// src/App.js
import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import Farbsuche from './Farbsuche'; // Importiere die Farbsuche-Komponente

const App = () => {
  const [data, setData] = useState([]);  // CSV-Daten werden hier gespeichert
  const [searchTerm, setSearchTerm] = useState('');  // Suchbegriff
  const [result, setResult] = useState('');  // Suchergebnis

  // CSV-Datei wird bei Initialisierung geladen
  useEffect(() => {
    Papa.parse('/farben.csv', {
      header: true,
      download: true,
      complete: (results) => {
        setData(results.data);  // CSV-Daten in den Zustand speichern
      },
    });
  }, []);

  // Suchfunktion
  const handleSearch = () => {
    const normalizedSearchTerm = searchTerm.replace(/#/g, '').toLowerCase();  // # entfernen und in Kleinbuchstaben umwandeln

    // Suche nach Farbcode oder Farbe
    const item = data.find(
      (d) => d.farbcode.toLowerCase() === normalizedSearchTerm || d.farbe.toLowerCase() === normalizedSearchTerm
    );

    // Wenn ein passendes Item gefunden wurde
    if (item) {
      // Wenn nach Farbcode gesucht wurde (z.B. #01#), zeige die Farbe (z.B. #Weiß#)
      if (item.farbcode.toLowerCase() === normalizedSearchTerm) {
        setResult(`= ${item.farbe}`);
      }
      // Wenn nach dem Farbnamen gesucht wurde (z.B. #Weiß#), zeige den Farbcode (z.B. #01#)
      else if (item.farbe.toLowerCase() === normalizedSearchTerm) {
        setResult(`= ${item.farbcode}`);
      }
    } else {
      setResult('Nicht gefunden');  // Kein Ergebnis
    }
  };

  return (
    <Farbsuche
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      handleSearch={handleSearch}
      result={result}
    />
  );
};

export default App;
