/* Farbsuche.js */
import React from 'react';

const Farbsuche = ({ searchTerm, setSearchTerm, handleSearch, result }) => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-50 to-blue-100 p-4">
            {/* Container für die Suchkomponente */}
            <div className="flex flex-col items-center bg-white p-6 rounded-xl shadow-lg w-96">
                {/* Titel der Anwendung */}
                <h1 className="text-3xl font-extrabold text-blue-600 mb-6">Farbsuche</h1>

                {/* Suchfeld */}
                <input
                    type="text"
                    placeholder="Farbcode oder Farbe eingeben"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="p-3 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-4 focus:ring-blue-300 focus:border-transparent transition duration-300 w-full"
                />

                {/* Such-Button */}
                <button
                    onClick={handleSearch}
                    className="mt-4 w-full p-3 bg-blue-600 text-white font-semibold rounded-full shadow-lg hover:bg-blue-700 hover:shadow-xl transition duration-300 transform hover:scale-105"
                >
                    Suchen
                </button>

                {/* Ergebnisanzeige */}
                <p className="mt-6 text-xl text-gray-700">{result}</p>
            </div>
        </div>
    );
};

export default Farbsuche;
